// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import find from 'lodash/find'
import matches from 'lodash/matches'
import toNumber from 'lodash/toNumber'
import kebabCase from 'lodash/kebabCase'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { compiler } from 'markdown-to-jsx'

import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    nav: allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          position
          routeSlug
          title {
            lang
            content
          }
          abstract
        }
      }
    }
    refs: allResources(filter: { routeSlug: { eq: "/unlinked-index" } }) {
      edges {
        node {
          indexRefs {
            routeSlug
            index
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const TOCRenderer = React.memo(
  ({ nodes, nodePositions, indexRefs, update, lang }) => (
    <ul className="as-paragraph homepage-table-of-contents">
      {map(nodePositions, (nodePosition) => {
        const thisNode = filter(nodes, matches({ position: nodePosition }))[0]
        const sectionRefs = find(indexRefs, ['routeSlug', thisNode.routeSlug])
        console.log(thisNode)

        return (
          <li id={`j${nodePosition}`}>
            <h2>{thisNode.intlTitle}</h2>
            <p>
              {compiler(thisNode.abstract, { wrapper: null })}
              &nbsp;<Link to={thisNode.routeSlug}>Continue reading ⟶</Link>
            </p>
            <p>
              <strong>In this journal:</strong>
            </p>
            <ul>
              {map(sectionRefs.index, (ref) => (
                <li>
                  <Link
                    to={`${thisNode.routeSlug}#${kebabCase(ref)}`}
                    onClick={() => update(false, 'root')}
                  >
                    <span>{ref}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        )
      })}
    </ul>
  )
)

/** TableOfContents */
const TableOfContents = React.memo(({ update = () => true, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const nodes = map(data.nav.edges, 'node').slice(0, -2)
      const intlNodes = map(nodes, (node) => ({
        intlTitle: filter(node.title, ['lang', lang])[0].content,
        ...node,
      }))
      const nodePositions = map(
        Object.keys(groupBy(map(intlNodes, 'position'), Math.floor)),
        toNumber
      )

      return (
        <TOCRenderer
          nodes={intlNodes}
          indexRefs={data.refs.edges[0].node.indexRefs}
          nodePositions={nodePositions}
          update={update}
        />
      )
    }}
  />
))

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TableOfContents
