// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

import Gallery from '../gallery'
import '../gallery/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "bw/img94_BW.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic1: file(relativePath: { eq: "link/03.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic2: file(relativePath: { eq: "link/06.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic3: file(relativePath: { eq: "link/08-matrimandir-issue-1-1998-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic4: file(relativePath: { eq: "link/10-mm-bon-11.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic5: file(relativePath: { eq: "link/21.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic6: file(relativePath: { eq: "link/35.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic7: file(relativePath: { eq: "link/39.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic8: file(relativePath: { eq: "link/43.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic9: file(relativePath: { eq: "link/n10-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view1: file(relativePath: { eq: "link/n11-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view2: file(relativePath: { eq: "link/n11-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view3: file(relativePath: { eq: "link/n11-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view4: file(relativePath: { eq: "link/n11-05.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view5: file(relativePath: { eq: "link/n11-06.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view6: file(relativePath: { eq: "link/n11-07.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view7: file(relativePath: { eq: "link/n11-18.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view8: file(relativePath: { eq: "link/n11-21.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    view9: file(relativePath: { eq: "link/n11-22.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang, mediaState }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const i1 = pickBy(data, (value, key) => startsWith(key, 'pic'))
      const i2 = pickBy(data, (value, key) => startsWith(key, 'view'))

      const images1 = []
      const images2 = []

      map(i1, (i) => {
        images1.push({
          data: i,
          height: i.childImageSharp.gatsbyImageData.height,
          width: i.childImageSharp.gatsbyImageData.width,
        })
      })

      map(i2, (i) => {
        images2.push({
          data: i,
          height: i.childImageSharp.gatsbyImageData.height,
          width: i.childImageSharp.gatsbyImageData.width,
        })
      })

      const { screenHeight = 1000 } = mediaState
      const rowHeight = screenHeight * 0.15

      return (
        <Fragment>
          <div className="title">
            <h1>Matrimandir Journal</h1>
            <p>
              The Matrimandir Journal is an exploration into the deeper meaning
              of the centre of Auroville - the Matrimandir – from where the city
              is to radiate into its manifold outward expressions.
            </p>
          </div>
          <div className="visuals" id="visuals">
            <Gallery images={images1} targetRowHeight={rowHeight} />
            <GatsbyImage className="flow" image={getImage(data.img)} />
            <Gallery images={images2} targetRowHeight={rowHeight} />
          </div>
          <div className="about">
            <TableOfContents lang={lang} />
          </div>
        </Fragment>
      )
    }}
  />
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedCoverPage = compose(
  withState // Add state
)(CoverPage)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedCoverPage
